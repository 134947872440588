import './App.css';
import imgDesktop from './assets/desktop.png'
import imgMovil from './assets/movil.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://denda.cl/batitu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={imgDesktop} alt="img-desktop" className='imgDesktop'/>
          <img src={imgMovil} alt="img-movil" className='imgMovil'/>
        </a>
      </header>
    </div>
  );
}

export default App;
